import { SideBarFooterLogo } from "icons/icons"

export const SideBarFooter = () => {
  return (
    <div className="flex justify-center items-center gap-3">
      <div className="text-xs leading-4 font-normal text-gray-900">
        Powered by
      </div>
      <SideBarFooterLogo />
    </div>
  )
}
