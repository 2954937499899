import { LayoutProps, useTranslate } from "@pankod/refine-core"
import { Nav } from "./Nav/Nav"
import { DrawerWrapper } from "./drawer/DrawerWrapper"
import { cleanupAndClose } from "utilities/cleanUp"

export const Layout: React.FC<LayoutProps> = ({ children, Sider }) => {
  const showSider = !!Sider
  const actingAsRole = localStorage.getItem("actAsRole") === "true"
  const translate = useTranslate()
  const handleClick = () => {
    cleanupAndClose()
  }

  return (
    <div className="flex flex-col w-full bg-one-gray-100 min-h-screen">
      {actingAsRole && (
        <div className="flex justify-center items-center bg-yellow-300 py-2">
          <div className="flex items-center font-medium">
            {translate("actAsRole.you_are")}
            <p className="font-bold ml-1">
              {translate("actAsRole.organizationOwner")}
            </p>
            <button
              className="ml-2 text-white bg-one-gray-950 px-2 py-1 rounded-md hover:bg-one-gray-950/80"
              onClick={handleClick}
            >
              {translate("actAsRole.leave")}
            </button>
          </div>
        </div>
      )}

      <Nav />

      <div className="flex flex-1">
        {showSider && <Sider />}
        <div className=" bg-white flex-1 flex-col overflow-hidden">
          <DrawerWrapper />
          <div className="mx-auto max-w-7xl sm:px-6 lg:px-8">{children}</div>
        </div>
      </div>
    </div>
  )
}
