import { useCustom } from "@pankod/refine-core"
import { IOrganization } from "interfaces"

export const CompanyPicker = () => {
  const { data: organizationData, isLoading } = useCustom<IOrganization>({
    url: "organizations/",
    method: "get"
  })

  if (isLoading) return null
  const currentOrganizationName: string = organizationData?.data[0].name
  return (
    <div className="flex items-center mr-2 gap-2">
      <div className="flex flex-shrink-0 items-center justify-center rounded-full font-normal text-sm border border-gray w-8 h-8">
        {currentOrganizationName.charAt(0)}
      </div>
      <div className="flex-grow overflow-hidden inline">
        <div className="truncate">{currentOrganizationName}</div>
      </div>
    </div>
  )
}
