import React from "react"
import clsx from "clsx"

interface IButtonProps {
  text: string
  onClick?: () => void
  variant?: "primary" | "secondary" | "text"
  icon?: React.ReactNode
  disabled?: boolean
  className?: string
  type?: "button" | "submit" | "reset"
}

export const Button = ({
  text,
  onClick,
  variant = "secondary",
  icon,
  disabled = false,
  className = "",
  type = "button"
}: IButtonProps) => {
  const baseStyles =
    "flex justify-center items-center rounded-md border border-gray-300 bg-white shadow-sm py-1.5 px-2.5 gap-1 font-normal text-sm"
  const variantStyles = {
    primary: "",
    secondary: "text-black",
    text: ""
  }

  const finalClassNames = clsx(
    baseStyles,
    variantStyles[variant],
    disabled && "opacity-50 cursor-not-allowed",
    className
  )

  return (
    <button
      type={type}
      onClick={onClick}
      className={finalClassNames}
      disabled={disabled}
    >
      {icon && <span className="mr-2">{icon}</span>}
      {text}
    </button>
  )
}
