import {
  faEllipsisVertical,
  faSignInAlt,
  faSignOutAlt,
  faEdit,
  faCancel,
  faPrint,
  faBullhorn,
  faCaretUp,
  faCaretDown
} from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { Menu } from "@headlessui/react"
import { usePermissions, useTranslate } from "@pankod/refine-core"
import { Row } from "@pankod/refine-react-table"
import { useUpdateVisitor } from "hooks/useUpdateVisitorData"
import { useUpdateVisitorGroup } from "hooks/useUpdateVisitorGroupData"
import { IHost, IVisit } from "interfaces"
import { CustomMenuItem } from "modules/Visit/CustomMenuItem"
import { useRef, useState } from "react"
import ReactDOM from "react-dom"
import { setIsPrintVisitBadgeOpen } from "reduxStore/reducers/visitPrintBadgeReducer"
import { useAppDispatch } from "reduxStore/store"
import { IS_PREVIEW_MODE } from "utilities/development"
import { LOG, Logger } from "utilities/logger"
import {
  useHasPermission,
  visitPermissionEnabled
} from "utilities/permissionSelector"

export const ActionsCell = (
  row: Row<IVisit>,
  onEdit: (row: Row<IVisit>) => void,
  setShowDeleteModal: (value: boolean) => void,
  setSelectedVisitId: (value: number) => void,
  setSelectedVisitorId: (value: number | undefined) => void,
  showQuickNotificationModal: boolean,
  setShowQuickNotificationModal: (value: boolean) => void,
  setVisitHost: (value: {
    organizationId: number | undefined
    hostId: number | undefined
    host: IHost | null
  }) => void
) => {
  const { id, host, workspace, visitor } = row.original

  const translate = useTranslate()
  const dispatch = useAppDispatch()
  const { handleVisitorUpdate } = useUpdateVisitor()
  const { handleVisitorGroupUpdate } = useUpdateVisitorGroup()
  const { data: permissionsData } = usePermissions<string>()
  const canRemoveVisit = visitPermissionEnabled(permissionsData)
  const axialOffset = 220
  const hasEditVisitPermissions = useHasPermission("Visit", "ReadWrite")
  const [menuStyles, setMenuStyles] = useState({ top: 0, left: 0 })
  const handleMenuPosition = (triggerElement: HTMLElement) => {
    const rect = triggerElement.getBoundingClientRect()

    setMenuStyles({
      top: rect.bottom + window.scrollY - axialOffset,
      left: rect.left + window.scrollX - axialOffset
    })
  }
  const buttonRef = useRef<HTMLButtonElement | null>(null)
  return (
    <div className="flex gap-4">
      <Menu
        as="div"
        className="relative inline-flex text-left"
        onClick={(event) => {
          event.stopPropagation()
        }}
      >
        <Menu.Button
          ref={buttonRef}
          className="rounded-full w-10 h-10 border border-gray-200 p-2 text-lg font-medium transition duration-150 ease-in-out !bg-blue-100 hover:!bg-blue-300 hover:text-white"
          onClick={(event) => {
            event.stopPropagation()
            if (buttonRef.current) {
              handleMenuPosition(buttonRef.current)
            }
          }}
        >
          <FontAwesomeIcon
            icon={faEllipsisVertical}
            className="align-center self-center"
          />
        </Menu.Button>
        {ReactDOM.createPortal(
          <Menu.Items
            style={{
              position: "absolute",
              top: menuStyles.top,
              left: menuStyles.left,
              zIndex: 1000
            }}
            className="absolute z-50 right-10 w-56 origin-top-right divide-y divide-gray-100 rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none"
          >
            <div className="px-1 py-1">
              <Menu.Item>
                {({ active }) => (
                  <CustomMenuItem
                    active={active}
                    icon={faSignInAlt}
                    label={translate("table.visits.signIn")}
                    onClick={() =>
                      row.original.visitors_count > 1
                        ? handleVisitorGroupUpdate(id, { state: "signed_in" })
                        : handleVisitorUpdate(
                            id,
                            visitor?.visitor_id as number,
                            {
                              state: "signed_in"
                            }
                          )
                    }
                  />
                )}
              </Menu.Item>
              <Menu.Item>
                {({ active }) => (
                  <CustomMenuItem
                    active={active}
                    icon={faSignOutAlt}
                    label={translate("table.visits.signOut")}
                    onClick={() =>
                      row.original.visitors_count > 1
                        ? handleVisitorGroupUpdate(id, { state: "signed_out" })
                        : handleVisitorUpdate(
                            id,
                            visitor?.visitor_id as number,
                            {
                              state: "signed_out"
                            }
                          )
                    }
                  />
                )}
              </Menu.Item>
            </div>
            <div className="px-1 py-1">
              {IS_PREVIEW_MODE && hasEditVisitPermissions && (
                <Menu.Item>
                  {({ active }) => (
                    <CustomMenuItem
                      active={active}
                      icon={faEdit}
                      label={translate("table.visits.editVisit")}
                      onClick={() => onEdit(row)}
                    />
                  )}
                </Menu.Item>
              )}
              {canRemoveVisit && (
                <Menu.Item>
                  {({ active }) => (
                    <CustomMenuItem
                      active={active}
                      icon={faCancel}
                      label={translate("table.visits.deleteVisit")}
                      onClick={() => {
                        setSelectedVisitId(id)
                        setShowDeleteModal(true)
                      }}
                    />
                  )}
                </Menu.Item>
              )}
            </div>
            <div className="px-1 py-1">
              <Menu.Item>
                {({ active }) => (
                  <CustomMenuItem
                    active={active}
                    icon={faPrint}
                    label={translate("table.visits.printBadge")}
                    onClick={() => {
                      setSelectedVisitId(id)
                      setSelectedVisitorId(visitor?.visitor_id)
                      setVisitHost({
                        hostId: host?.host_id,
                        host: host,
                        organizationId: workspace?.organization?.id
                      })
                      dispatch(setIsPrintVisitBadgeOpen(true))
                    }}
                  />
                )}
              </Menu.Item>
              <Menu.Item disabled>
                {({ active }) => (
                  <CustomMenuItem
                    active={active}
                    icon={faPrint}
                    label={translate("table.visits.prePrintBadges")}
                    disabled={true}
                  />
                )}
              </Menu.Item>
            </div>
            <div className="px-1 py-1">
              <Menu.Item>
                {({ active }) => (
                  <CustomMenuItem
                    active={active}
                    icon={faBullhorn}
                    label={translate("table.visits.notifyHost")}
                    onClick={() => {
                      void Logger().log(LOG.PRESS_HOST_NOTIFY)
                      setVisitHost({
                        hostId: host?.host_id,
                        host: host,
                        organizationId: workspace?.organization?.id
                      })
                      setShowQuickNotificationModal(!showQuickNotificationModal)
                    }}
                  />
                )}
              </Menu.Item>
            </div>
          </Menu.Items>,
          document.body
        )}
      </Menu>
      <button
        className="rounded-full w-10 h-10 border border-gray-200 p-2 text-lg font-medium leading-tight transition duration-150 ease-in-out bg-blue-100 hover:bg-blue-300 hover:text-white cursor-pointer items-center"
        onClick={(event) => {
          event.stopPropagation()
          row.toggleExpanded()
        }}
      >
        {row.getIsExpanded() ? (
          <FontAwesomeIcon icon={faCaretUp} />
        ) : (
          <FontAwesomeIcon icon={faCaretDown} />
        )}
      </button>
    </div>
  )
}
