import React from "react"

interface IconPath {
  d?: string
  stroke?: string
  fill?: string
  strokeWidth?: string
  strokeLinecap?: "round" | "butt" | "square" | "inherit"
  strokeLinejoin?: "round" | "miter" | "bevel" | "inherit"
  width?: number
  height?: number
  x?: number
  y?: number
  rx?: number
  ry?: number
}

interface IconProps {
  width?: number
  height?: number
  viewBox?: string
  paths: Array<IconPath>
  fill?: string
  strokeWidth?: string
  strokeLinecap?: "round" | "butt" | "square" | "inherit"
  strokeLinejoin?: "round" | "miter" | "bevel" | "inherit"
  className?: string
}

export const GenericIcon: React.FC<IconProps> = ({
  width = 20,
  height = 20,
  viewBox = "0 0 20 20",
  paths,
  fill = "none",
  strokeWidth = "1.5",
  strokeLinecap = "round",
  strokeLinejoin = "round",
  className = ""
}) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox={viewBox}
      fill={fill}
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      {paths.map((path, index) =>
        path.d ? (
          <path
            key={index}
            d={path.d}
            stroke={path.stroke || "#919191"}
            fill={path.fill || fill}
            strokeWidth={path.strokeWidth || strokeWidth}
            strokeLinecap={path.strokeLinecap || strokeLinecap}
            strokeLinejoin={path.strokeLinejoin || strokeLinejoin}
          />
        ) : (
          <rect
            key={index}
            x={path.x || 0}
            y={path.y || 0}
            width={path.width || 0}
            height={path.height || 0}
            rx={path.rx || 0}
            ry={path.ry}
            fill={path.fill || fill}
          />
        )
      )}
    </svg>
  )
}
