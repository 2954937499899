import { useMenu, usePermissions, useCan, ITreeMenu } from "@pankod/refine-core"
import { getPermittedMenuItems } from "utilities/permissionSelector"
import { SideBarMenuItemWrapper } from "./SideBarMenuItemWrapper"
import { SideBarFooter } from "./SiderFooter"
import { SideMenuItem } from "./SideMenuItem"

export const Sider: React.FC = () => {
  const { menuItems } = useMenu()
  const { data: permissionsData } = usePermissions<string>()

  const { data: canAccessVisitModule, isLoading } = useCan({
    resource: "visits",
    action: "list",
    params: {
      resource: {
        name: "",
        options: {
          name: "",
          modules: ["visit"]
        }
      }
    }
  })

  const newMenuItems = [...getPermittedMenuItems(permissionsData, menuItems)]

  const result = newMenuItems.reduce((acc: ITreeMenu[], currentItem) => {
    const moduleName =
      (currentItem.options?.modules?.[0] as string) || undefined

    const { label, key, name, icon, ...otherProps } = currentItem

    if (!name) return acc

    if (moduleName) {
      let relevantItem = acc.find((item) => item.name === moduleName)

      if (!relevantItem) {
        relevantItem = {
          name: moduleName,
          label: moduleName.charAt(0).toUpperCase() + moduleName.slice(1),
          children: []
        }
        acc.push(relevantItem)
      }

      relevantItem.children.push({
        label,
        key: key || "",
        name,
        icon,
        ...otherProps
      })
    } else {
      acc.push({
        name,
        children: [{ key, name, label, icon, ...otherProps }]
      })
    }

    return acc
  }, [] as ITreeMenu[])

  const itemsWithLabel = result.filter((item) => item.label)
  const itemsWithoutLabel = result.filter((item) => !item.label)

  return (
    <div className="flex flex-col min-w-72 bg-one-gray-100 mt-6">
      <div className="flex flex-col flex-grow px-4">
        {itemsWithLabel.length > 0 &&
          itemsWithLabel.map(({ label, children, key }) => (
            <SideBarMenuItemWrapper
              title={label || ""}
              key={key}
              children={children}
              loading={isLoading}
              locked={!canAccessVisitModule}
            />
          ))}
      </div>
      <div className="flex flex-col px-4">
        {itemsWithoutLabel.length > 0 &&
          itemsWithoutLabel.map(({ children, key }) =>
            children.map(({ label, key, ...otherProps }) => (
              <SideMenuItem key={key} label={label} {...otherProps} />
            ))
          )}
      </div>
      <div className="flex items-center ml-4 h-20">
        <SideBarFooter />
      </div>
    </div>
  )
}
