/**
 * Cleans up browser storage and closes the window
 * Removes all data from both session and local storage
 * Note: window.close() may not work if the window wasn't opened by JavaScript
 */
export const cleanupAndClose = (): void => {
  window.sessionStorage.clear()
  localStorage.clear()
  window.close()
}
