import { SideMenuItem } from "./SideMenuItem"
import { ITreeMenu } from "@pankod/refine-core/dist/interfaces"

type SideBarMenuItemWrapperProps = {
  title: string
  children: ITreeMenu[]
  locked?: boolean
  loading?: boolean
}

export const SideBarMenuItemWrapper = ({
  title = "",
  children,
  locked,
  loading
}: SideBarMenuItemWrapperProps) => {
  if (loading && locked) {
    return null
  }

  return (
    <div>
      <div className="flex-grow truncate text-one-gray-600">{title}</div>
      {children.map(({ label, key, ...otherProps }) =>
        title ? (
          <SideMenuItem key={key} label={label} {...otherProps} />
        ) : (
          <div key={key}>
            <div key={key} className="mt-auto">
              <SideMenuItem key={key} label={label} {...otherProps} />
            </div>
          </div>
        )
      )}
    </div>
  )
}
