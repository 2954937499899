import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"

interface MenuItemProps {
  active: boolean
  icon: any
  label: string
  onClick?: () => void
  disabled?: boolean
}

export const CustomMenuItem = ({
  active,
  icon,
  label,
  onClick,
  disabled = false
}: MenuItemProps) => (
  <button
    onClick={onClick}
    disabled={disabled}
    className={`${
      active
        ? "font-medium bg-systam-blue text-white"
        : "bg-white text-gray-600"
    } group flex w-full items-center rounded-md px-2 py-2 text-sm ${
      disabled ? "opacity-50 cursor-not-allowed" : "hover:bg-gray-100"
    }`}
  >
    <FontAwesomeIcon
      icon={icon}
      className={`mr-4 w-4 h-4 ${
        active ? "text-white" : "text-systam-blue hover:text-white"
      }`}
    />
    <span>{label}</span>
  </button>
)
