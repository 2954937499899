import { useEffect } from "react"
import { isEmptyString } from "utilities/string"
import queryString, { ParsedQuery } from "query-string"
import {
  ACCESS_TOKEN_KEY,
  EXPIRES_KEY,
  PERMISSION_KEY,
  REFRESH_TOKEN_KEY,
  SCOPE_ID,
  USER_ID_KEY
} from "utilities/constants"
import { axiosInstance } from "utilities/dataProvider"
import { useNavigation } from "@pankod/refine-core"

export const Impersonate = () => {
  // Hide help-widget
  if (window) {
    window.FreshworksWidget("hide")
  }
  const { replace } = useNavigation()

  const getPermissions = async () => {
    return await axiosInstance.get("/users/me").then((res) => {
      return res.data
    })
  }

  useEffect(() => {
    const searchParams = window.location.search
    if (!isEmptyString(searchParams)) {
      const qs = queryString.parse(searchParams)
      if (qs) {
        clearStorage()
        setLocalStorage(qs)
        handleAccessToken()
      }
    }
  }, [])

  const clearStorage = () => {
    window.sessionStorage.clear()
    localStorage.clear()
  }

  const setLocalStorage = (qs: ParsedQuery) => {
    if (qs.scope !== null && qs.scope !== undefined) {
      localStorage.setItem(SCOPE_ID, qs.scope.toString())
    }
    if (qs.token !== null && qs.token !== undefined) {
      localStorage.setItem(ACCESS_TOKEN_KEY, qs.token.toString())
    }
    localStorage.setItem(REFRESH_TOKEN_KEY, "")
    if (qs.expire !== null && qs.expire !== undefined) {
      localStorage.setItem(EXPIRES_KEY, qs.expire.toString())
    }
    localStorage.setItem(USER_ID_KEY, "0")
    localStorage.setItem("actAsRole", "true")
  }

  const handleAccessToken = () => {
    if (localStorage.getItem(ACCESS_TOKEN_KEY)) {
      getPermissions()
        .then((permissions) => {
          localStorage.setItem(
            PERMISSION_KEY,
            JSON.stringify(permissions.role.permissions)
          )
          console.log("Redirecting...")
          replace("../")
          window.location.reload()
        })
        .catch((e) => console.log(e))
    } else {
      console.log("No access token")
    }
  }

  return <div></div>
}
