import { ChangeEvent, useEffect, useState } from "react"
import { useTranslate } from "@pankod/refine-core"
import { DateTime } from "luxon"

interface TimePickerProps {
  title?: string
  initialVisitTime?: string | null | undefined
  startDate?: string | null | undefined
  editMode?: boolean
  onChange: (value: string) => void
  isDisabled?: boolean
}

export const VisitTimePicker = ({
  title,
  initialVisitTime,
  startDate,
  editMode,
  onChange,
  isDisabled
}: TimePickerProps) => {
  const translate = useTranslate()
  const hours = Array.from({ length: 24 }, (_, i) => i)
  const minutes = Array.from({ length: 4 }, (_, i) => i * 15)
  const [selectedHour, setSelectedHour] = useState<string>("")
  const [selectedMinute, setSelectedMinute] = useState<string>("")

  const [currentDateTime, setCurrentDateTime] = useState<DateTime>(
    DateTime.local()
  )

  useEffect(() => {
    const now = DateTime.local()
    setCurrentDateTime(now)
    if (initialVisitTime) {
      const [hour, minute] = initialVisitTime.split(":").map(Number)
      if (hour) setSelectedHour(hour.toString().padStart(2, "0"))
      if (minute || minute === 0)
        setSelectedMinute(minute.toString().padStart(2, "0"))
    }
  }, [])

  const hourOptions = hours.map((hour) => {
    if (!editMode || (selectedHour && selectedMinute)) {
      const value = hour.toString().padStart(2, "0")
      if (startDate) {
        const selectedDate = DateTime.fromISO(startDate)
        // If the selected date is not today, do not filter any options.
        if (selectedDate.toISODate() !== currentDateTime.toISODate()) {
          return (
            <option key={value} value={value}>
              {value}
            </option>
          )
        } else if (
          currentDateTime.hour < hour ||
          (currentDateTime.hour === hour && currentDateTime.minute <= 45)
        ) {
          return (
            <option key={value} value={value}>
              {value}
            </option>
          )
        }
      }
    }
    return null
  })

  const minuteOptions = minutes.map((minute) => {
    if (!editMode || (selectedHour && selectedMinute)) {
      const value = minute.toString().padStart(2, "0")
      // If same hour as current time, filter past minutes.
      if (
        selectedHour === currentDateTime.hour.toString().padStart(2, "0") &&
        currentDateTime.minute < minute
      ) {
        return (
          <option key={value} value={value}>
            {value}
          </option>
        )
      } else if (
        parseInt(selectedMinute) < minute ||
        parseInt(selectedHour) > currentDateTime.hour ||
        (startDate &&
          DateTime.fromISO(startDate).toISODate() !==
            currentDateTime.toISODate())
      ) {
        return (
          <option key={value} value={value}>
            {value}
          </option>
        )
      }
    }
    return null
  })

  function selectFirstMinuteOption() {
    const value = minuteOptions.find(
      (option) => option?.props.value !== undefined
    )?.props.value
    if (value !== undefined) {
      setSelectedMinute(value as string)
    }
  }

  useEffect(() => {
    const firstValue = minuteOptions.find(
      (option) => option?.props.value !== undefined
    )?.props.value
    if (!selectedMinute || (selectedMinute < firstValue && !editMode)) {
      selectFirstMinuteOption()
    }
  }, [minuteOptions])

  useEffect(() => {
    const firstValue = hourOptions.find(
      (option) => option?.props.value !== undefined
    )?.props.value

    if (!selectedHour || (selectedHour < firstValue && !editMode)) {
      if (firstValue !== undefined) {
        setSelectedHour(firstValue as string)
      }
    }
  }, [hourOptions])

  useEffect(() => {
    if (selectedHour) {
      if (!editMode) {
        selectFirstMinuteOption()
      } else if (initialVisitTime) {
        const [hour] = initialVisitTime.split(":").map(Number)
        if (hour && selectedHour !== hour.toString().padStart(2, "0")) {
          selectFirstMinuteOption()
        }
      }
      handleChange(`${selectedHour}:${selectedMinute}`)
    }
  }, [selectedHour])

  useEffect(() => {
    if (selectedMinute) {
      handleChange(`${selectedHour}:${selectedMinute}`)
    }
  }, [selectedMinute])

  const handleChangeMinute = (event: ChangeEvent<HTMLSelectElement>) => {
    const minute = event.target.value
    setSelectedMinute(minute)
  }

  const handleChangeHour = (event: ChangeEvent<HTMLSelectElement>) => {
    const hour = event.target.value
    setSelectedHour(hour)
  }

  const handleChange = (time: string) => {
    onChange(time)
  }

  return (
    <div className="flex flex-col w-full">
      {title && <label className="pb-1">{translate(title)}</label>}
      <div className="flex flex-row gap-1">
        <select
          className="bg-gray-100 rounded-lg p-2 pr-6 text-center w-20"
          onChange={handleChangeHour}
          value={selectedHour}
          disabled={isDisabled}
        >
          {hourOptions}
        </select>
        <select
          className="bg-gray-100 rounded-lg p-2 pr-6 text-center w-20"
          onChange={handleChangeMinute}
          value={selectedMinute}
          disabled={isDisabled}
        >
          {minuteOptions}
        </select>
      </div>
    </div>
  )
}
