import React, { useEffect, useState } from "react"
import { useAppDispatch } from "reduxStore/store"
import { resetWorkspace } from "reduxStore/reducers/workspaceReducer"
import { LoginForm } from "./Login/LoginForm"
import { ResetPassword } from "./Login/ResetPassword"
import { useTranslate } from "@pankod/refine-core"
import { LanguageDropdown } from "./LanguageDropdown"
import { useFeatureFlagEnabled } from "posthog-js/react"

import greetMeetRepeat from "assets/images/systam_greet_meet_repeat.svg"
import {
  FooterContentItem,
  FooterContentItemSeparator
} from "components/footer/Footer"

const MainDivContent = ({
  content,
  setContent,
  showDisabledFeatures
}: {
  content: string
  setContent: React.Dispatch<React.SetStateAction<string>>
  showDisabledFeatures: boolean
}) => {
  return (
    <>
      {content === "login" && (
        <LoginForm
          showDevContent={showDisabledFeatures}
          setContent={setContent}
        />
      )}
      {content === "resetPassword" && <ResetPassword />}
    </>
  )
}

const FooterContent = ({
  content,
  setContent,
  showDisabledFeatures
}: {
  content: string
  setContent: React.Dispatch<React.SetStateAction<string>>
  showDisabledFeatures: boolean
}) => {
  const translate = useTranslate()
  const showTermsOfServiceLink =
    useFeatureFlagEnabled("show-terms-of-service-link") ?? false

  return (
    <>
      {content === "login" && (
        <div>
          {translate("pages.login.newToSystam")}
          <a
            className="ml-2 font-medium text-one-rose-400 hover:text-one-rose-500"
            href="https://systam.io"
            target="_blank"
            rel="noreferrer"
          >
            {translate("pages.login.getStarted")}
          </a>
        </div>
      )}

      {content === "resetPassword" && (
        <div>
          {translate("pages.login.backTo")}
          <button
            onClick={() => setContent("login")}
            className="ml-2 font-medium text-one-rose-400 hover:text-one-rose-500"
          >
            {translate("pages.login.login")}
          </button>
        </div>
      )}
      <div className="mt-28 flex justify-start items-center space-x-4">
        <FooterContentItem href="https://systam.io">
          {translate("footer.about")}
        </FooterContentItem>
        <FooterContentItemSeparator />
        {showTermsOfServiceLink && (
          <>
            <FooterContentItem href="https://app.systam.io/terms-of-service">
              {translate("footer.termsOfService")}
            </FooterContentItem>
            <FooterContentItemSeparator />
          </>
        )}
        <FooterContentItem href="https://systam.io/service-privacy-policy/">
          {translate("footer.privacyPolicy")}
        </FooterContentItem>
      </div>
    </>
  )
}

export const Login = () => {
  const dispatch = useAppDispatch()
  const [content, setContent] = useState("login")

  // Show development features toggle. Uses PostHog feature flags.
  const showDisabledFeatures =
    useFeatureFlagEnabled("show-disabled-features") ?? false

  // Hide help-widget
  if (window) {
    window.FreshworksWidget("hide")
  }

  useEffect(() => {
    /**
     * Everytime Login component mounts, redux state resets.
     *
     * Make sure to reset redux state.
     * If user switches account and the redux state is NOT cleared,
     * then then current workspace that the previous user selected is still active,
     * but the new user might not have access to that workspace which causes errors.
     */

    dispatch(resetWorkspace())
  }, [])

  return (
    <div className="flex flex-col lg:flex-row w-full min-h-screen overflow-hidden">
      <div className="relative w-full h-full lg:w-1/2 flex flex-col items-center justify-start p-8 lg:pl-52 lg:pr-16 lg:py-16">
        <div className="flex justify-between items-center h-20">
          {showDisabledFeatures && <LanguageDropdown />}
        </div>
        <div className="flex flex-grow flex-col items-center justify-start">
          <div className="w-full lg:w-144">
            <MainDivContent
              content={content}
              setContent={setContent}
              showDisabledFeatures={showDisabledFeatures}
            />
            <div className="mt-6 flex flex-col ml-24">
              <FooterContent
                content={content}
                setContent={setContent}
                showDisabledFeatures={showDisabledFeatures}
              />
            </div>
          </div>
        </div>
      </div>
      <div className="relative w-full lg:w-1/2 h-64 lg:h-full">
        <img
          alt="Systam"
          className="absolute inset-0 object-cover object-center aspect-auto"
          src={greetMeetRepeat}
        />
      </div>
    </div>
  )
}
